import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Index',
  component: () => import('../views/Index.vue')
}, {
  path: '/result',
  name: 'Result',
  component: () => import('../views/Result.vue')
}, {
  path: '*',
  component: () => import('../views/Index.vue')
}]

const router = new VueRouter({
  mode: 'history',
  routes,
  // base: 'fund-ac',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

export default router